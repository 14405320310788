export enum PharmacyCode {
  Vendelso = 'vendelso',
}

export interface PharmacyInfo {
  code: PharmacyCode;
  name: string;
  email: string;
  phoneNumber: string;
  link: string;
}
